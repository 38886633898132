import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        気がつけば人生の半分を、このモンタージュに関わって生きてきました。<br />
        右も左もわからぬくせに生意気だけは一人前だった若手の頃から、たくさんの先輩方に指導していただき、たくさん叱られ、少しだけ褒められ、おかげさまで今ようやく人として形になってきたように思います。<br />
        デスク須田を、引き続き宜しくお願いいたします。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
